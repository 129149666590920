import { useState } from "react";
import classes from "./styles/carousel.module.css";
import custom_hero from "../images/custom_hero.png";
import desk_hero from "../images/desk_hero.png";
import gift_hero from "../images/gifts_hero.jpg";

const carousel = [custom_hero, desk_hero, gift_hero];

const Carousel = () => {
  const [isFirst, setIsFirst] = useState(0);

  const backwardHandler = (e) => {
    setIsFirst((prev) => {
      console.log(prev);
      if (prev === 0) {
        return 2;
      } else {
        return prev - 1;
      }
    });
  };

  const forwardHandler = (e) => {
    setIsFirst((prev) => {
      if (prev === 2) {
        return 0;
      } else {
        return prev + 1;
      }
    });
  };

  const moveToHandler = () => {};

  return (
    <div className={classes.img_container}>
      <div className={classes.img_carousel}>
        <button
          className={`${classes.carousel_button} ${classes.carousel_backward}`}
          onClick={backwardHandler}
        >
          &#60;
        </button>
        <div className={classes.carousel_container}>
          <ul className={classes.carousel_track}>
            <li className={classes.carousel_slide}>
              <img
                src={carousel[isFirst]}
                alt=""
                className={classes.creation}
              />
            </li>
          </ul>
        </div>
        <button
          className={`${classes.carousel_button} ${classes.carousel_forward}`}
          onClick={forwardHandler}
        >
          &#62;
        </button>
        <div className={classes.carousel_nav}>
          <button
            className={classes.carousel_indicator}
            onClick={moveToHandler}
          ></button>
          <button
            className={classes.carousel_indicator}
            onClick={moveToHandler}
          ></button>
          <button
            className={classes.carousel_indicator}
            onClick={moveToHandler}
          ></button>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
